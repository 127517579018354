import React, { useState, useEffect, useRef, useCallback } from 'react';
import '../styles/homepage.scss';
import Layout from 'components/Layout';
import Meta from 'components/Meta';
import typer from 'typer-js';
import { Link } from 'gatsby';
import Lottie from 'lottie-web';

import TestimonialsSlider from '../components/TestimonialsSlider/TestimonialsSlider';
import Questionnaire from '../components/Questionnaire';

const Homepage = () => {
  const [webFeedbackTabs, setwebFeedbackTabs] = useState(0);
  // const [bugTrackingTabs, setbugTrackingTabs] = useState(0);
  const [mobileAppTabs, setmobileAppTabs] = useState(0);
  const [customerFeedbackTabs, setcustomerFeedbackTabs] = useState(0);
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const lottieContainer = useRef();

  useEffect(() => {
    Lottie.loadAnimation({
      container: lottieContainer.current,
      path: '/assets/lotties/spinning-border.json',
      renderer: 'svg',
      loop: true,
      autoplay: true,
    });
  }, []);

  function webFeedbackTabsTrigger(value) {
    return () =>
      setwebFeedbackTabs((state) => (state === value ? value : value));
  }
  // function bugTrackingTrigger(value) {
  //   return () =>
  //     setbugTrackingTabs((state) => (state === value ? value : value));
  // }
  function mobileAppTrigger(value) {
    return () => setmobileAppTabs((state) => (state === value ? value : value));
  }
  function customerFeedbackTrigger(value) {
    return () =>
      setcustomerFeedbackTabs((state) => (state === value ? value : value));
  }

  const toggleQuestionnaire = () => setShowQuestionnaire(!showQuestionnaire);

  const stickyTrigger = useRef();
  const typerBlock = useRef();

  // useEffect(() => {
  //   const stickyWrapper = document.querySelector('.scrollspy-wrapper');
  //   if (stickyTrigger.current) {
  //     window.addEventListener('scroll', () => {
  //       if (window.pageYOffset > stickyTrigger.current?.offsetTop - 200) {
  //         stickyWrapper.classList.add('show');
  //       } else {
  //         stickyWrapper.classList.remove('show');
  //       }
  //     });
  //   }
  // }, []);

  function scrollHandler() {
    const position = window.pageYOffset;
    const phBanner = document.querySelector('.banner-producthunt');
    if (position > 150) {
      phBanner.classList.remove('static-PH');
      phBanner.classList.add('fixed-PH');
    } else {
      phBanner.classList.add('static-PH');
      phBanner.classList.remove('fixed-PH');
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  }, [scrollHandler]);

  const runTyper = useCallback(() => {
    const kill = new Event('killTyper');
    document.body.dispatchEvent(kill);

    const words = [
      'website',
      // 'web apps review',
      `mobile`,
      // 'Illustrations review',
      'customer',
    ];

    const instance = typer(typerBlock.current).cursor().line();

    words.forEach((word) => {
      instance

        .empty()
        .continue(word, 100)
        .pause(1000) // Pause for 1 second after each word is typed.
        .back('all', 5); // Erase that word, leaving a blank slate for the next one.
    });

    return instance.repeat(Infinity, true);
  }, []);

  useEffect(() => {
    runTyper();
  }, [runTyper]);

  return (
    <Layout homepage>
      <Meta
        title="Ruttl - Best Design Feedback Tool"
        description="Visual & Design Feedback Tool that helps you review design, content and report bugs on websites, applications, PDFs and images."
        url="https://ruttl.com"
      />

      {showQuestionnaire && (
        <Questionnaire
          showQuestionnaire={showQuestionnaire}
          toggleQuestionnaire={toggleQuestionnaire}
        />
      )}

      <div className="banner-producthunt hidden-mob static-PH">
        <img
          src="/assets/img/top-post-badge.svg"
          alt="ruttl - Collect&#0032;visual&#0032;feedback&#0032;directly&#0032;on&#0032;websites&#0044;&#0032;PDFs&#0032;&#0038;&#0032;images | Product Hunt"
          style={{ width: 250, height: 54 }}
          width="250"
          height="54"
        />
      </div>

      <section className="homepage-hero center">
        <div className="container">
          <div className="home-hero-content">
            <div className="product-hunt-badge ">
              <img
                src="/assets/img/top-post-badge.svg"
                alt="ruttl - Collect&#0032;visual&#0032;feedback&#0032;directly&#0032;on&#0032;websites&#0044;&#0032;PDFs&#0032;&#0038;&#0032;images | Product Hunt"
                style={{ width: 200, height: 44 }}
                width="200"
                height="44"
              />
            </div>

            <h1 className="mx-auto" style={{ maxWidth: 840 }}>
              One suite for all types of&nbsp;
              <span
                className="blue-text"
                style={{ display: 'inline-block' }}
                ref={typerBlock}
              />
              &nbsp;feedback
            </h1>

            <p>
              ruttl helps product, marketing, sales, and support teams work
              efficiently to grow revenues.
            </p>

            <div className="flex gap-12 justify-content-center flex-wrap">
              <Link
                to="/get-started"
                className="button"
                id="get-started-free-home-hero">
                Get started for free
              </Link>
              <a
                href="https://calendly.com/setup-demo-with-ruttl/30min"
                target="_blank"
                rel="noopener noreferrer"
                id="book-demo-home-hero"
                type="button"
                className="button book-demo">
                Book a Demo
              </a>
            </div>

            <div className="hero-review-block">
              <div className="review-stars">
                <img
                  src="/assets/img/stars.svg"
                  alt="Star ratings"
                  width={90}
                  height={19}
                />
                {/* <p>Based on 100+ reviews on</p> */}
              </div>

              <div className="review-logos">
                <img
                  src="/assets/img/logo/g2-crowd-full.png"
                  alt="G2 Crowd Logo"
                  width="70px"
                  height="20px"
                />
                <img
                  src="/assets/img/logo/capterra-full.png"
                  alt="Capterra Logo"
                  width="80px"
                  height="18px"
                />
                <img
                  src="/assets/img/logo/get-app-full.png"
                  alt="Get App Logo"
                  width="80px"
                  height="15px"
                />
              </div>
            </div>
          </div>

          <div className="products-list">
            <h2 className="center">Our Products</h2>
            <div className="flex-row justify-content-center our-products">
              <div className="">
                <Link to="/website-feedback-tool/" className="single-product">
                  <img
                    src="/assets/img/website-feedback-icon.svg"
                    alt="Website Feedback Tool Logo"
                    width={37}
                    height={37}
                  />
                  <p>Website Feedback Tool</p>
                </Link>
                <div className="subtext hidden-mob">
                  Comment, edit, and track bugs on live websites
                </div>
              </div>
              <div className="">
                <Link to="/mobile-app-feedback/" className="single-product">
                  <img
                    src="/assets/img/mobile-app-feedback-icon.svg"
                    alt="Mobile App Feedback Logo"
                    width={37}
                    height={37}
                  />
                  <p>Mobile App Feedback</p>
                </Link>
                <div className="subtext hidden-mob">
                  Report bugs on your mobile app using our plugin
                </div>
              </div>
              <div className="">
                <Link to="/video-annotation-tool/" className="single-product">
                  <img
                    src="/assets/img/video-review.svg"
                    alt="Video annotation tool Logo"
                    width={37}
                    height={37}
                    style={{
                      minHeight: '37px',
                    }}
                  />
                  <p>Video Annotation Tool</p>
                </Link>
                <div className="subtext hidden-mob">
                  Review and collaborate on videos
                </div>
              </div>
              <div className="hidden-web">
                <Link
                  to="/online-pdf-annotator-markup-software"
                  className="single-product">
                  <img
                    src="/assets/img/pdf-image.svg"
                    alt="PDF Review Logo"
                    width="37px"
                    height="37px"
                    style={{
                      maxHeight: '37px',
                    }}
                  />
                  <p>PDF/Image Review</p>
                </Link>
                <div className="subtext hidden-mob">
                  Share feedback and leave comment on PDFs and image files.
                </div>
              </div>
              <div className="hidden-web">
                <Link to="/customer-feedback-tool/" className="single-product">
                  <img
                    src="/assets/img/customer-feedback-icon.svg"
                    alt="Customer Feedback Logo"
                    width={37}
                    height={37}
                  />
                  <p>Customer Feedback Tool</p>
                </Link>
                <div className="subtext hidden-mob">
                  Chat with your website visitors and solve their issues
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ padding: '50px 0px 140px 0px' }}>
        <div className="container">
          <div className="flex-row">
            <div className="flex atlassian-feedback">
              <img
                src="/assets/img/atlassian-logo-round.webp"
                alt="Atlassian Logo"
              />

              <div className="atlassian-feedback-text">
                <p>
                  &quot;We loved using ruttl. The entire process right from
                  sign-up to commenting & managing feedback is so smooth.&quot;
                </p>

                <span>
                  <span className="bold">- Josh Shepherd, </span>
                  Event Tech Strategy at Atlassian
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container" ref={stickyTrigger}>
        <div
          className="single-product-section"
          data-spy-title="Website Feedback"
          data-spy-id="website-feedback">
          <div className="center">
            <div className="top-product-icon">
              <img
                src="/assets/img/website-feedback-icon.svg"
                alt="Website Feedback Tool Logo"
              />
            </div>

            <h2 className="mx-auto" style={{ maxWidth: 570 }}>
              Website Feedback Tool
            </h2>

            <Link href="/website-feedback-tool/" className="view-prod-button">
              View Product
              <svg width="6" height="12" viewBox="0 0 6 12" fill="none">
                <path
                  d="m1.031 10.063 3.938-4.22L1.03 1.626"
                  stroke="#6552FF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          </div>

          <div className="prod-tab-buttons">
            {[
              {
                id: 0,
                buttonText: 'Add Comment',
              },
              {
                id: 1,
                buttonText: 'Edit Content',
              },
              {
                id: 2,
                buttonText: 'Bug Tracking',
              },
              {
                id: 3,
                buttonText: 'Share with Guests',
              },
              {
                id: 4,
                buttonText: 'Review Web Apps',
              },
              {
                id: 5,
                buttonText: 'Annotate PDFs & Images',
              },
            ].map(({ id, buttonText }) => (
              <button
                onClick={webFeedbackTabsTrigger(id)}
                className={webFeedbackTabs === id ? 'active' : ''}
                type="button">
                {buttonText}
              </button>
            ))}
          </div>

          <div className="prod-tab-desc center">
            {[
              {
                id: 0,
                description: (
                  <>
                    Add design feedback by leaving text and video comments on
                    live websites to give contextual feedback, and add
                    attachment files as well.
                  </>
                ),
                img: '/assets/img/comment-website.webp',
              },
              {
                id: 1,
                description: (
                  <>
                    Make real-time CSS edits to visualise the final output. Edit
                    content, change fonts, spacing, margins and many more!
                  </>
                ),
                img: '/assets/img/edit-content-home.webp',
              },
              {
                id: 2,
                description: (
                  <>
                    Report issues directly on live product without the need of
                    screenshots and track all your tickets in Kanban style in
                    one place
                  </>
                ),
                videoURL: 'assets/video/ruttl-bug-tracking-demo.mp4',
              },
              {
                id: 3,
                description: (
                  <>
                    Collect feedback from your external guests/clients with just
                    a link which doesn&apos;t need a signup
                  </>
                ),
                img: '/assets/img/guest-sharing.webp',
              },
              {
                id: 4,
                description: (
                  <>
                    Review and streamline your feedback process by adding
                    comments and edits on your web app.
                  </>
                ),
                img: '/assets/img/web-apps.webp',
              },
              {
                id: 5,
                description: (
                  <>
                    Collect feedback as comments on your PDFs & images. Add the
                    updated files as versions to show the changes instantly!
                  </>
                ),
                img: '/assets/img/pdfs-images.webp',
              },
            ].map(({ id, description, img, videoURL }) => (
              <div
                key={id}
                className={`prod-tab-pane ${
                  webFeedbackTabs === id ? 'active' : ''
                }`}>
                <p className="center">{description}</p>

                <div className="prod-img">
                  {videoURL ? (
                    <video muted playsInline loop autoPlay src={videoURL} />
                  ) : (
                    <img src={img} alt="" loading="lazy" />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div
          data-spy-title="Mobile App Review"
          data-spy-id="mobible-app-review"
          className="single-product-section"
          style={{ backgroundColor: 'rgba(255, 225, 240, 0.3)' }}>
          <div className="center">
            <div className="top-product-icon">
              <img
                src="/assets/img/mobile-app-feedback-icon.svg"
                alt="Mobile App Feedback Logo"
              />
            </div>

            <h2 className="mx-auto" style={{ maxWidth: 570 }}>
              Mobile App Feedback
            </h2>

            <Link href="/mobile-app-feedback/" className="view-prod-button">
              View Product
              <svg width="6" height="12" viewBox="0 0 6 12" fill="none">
                <path
                  d="m1.031 10.063 3.938-4.22L1.03 1.626"
                  stroke="#6552FF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          </div>

          <div className="prod-tab-buttons">
            {[
              {
                id: 0,
                buttonText: 'Report Bug',
              },
              {
                id: 1,
                buttonText: 'Workflow',
              },
              {
                id: 2,
                buttonText: 'Assign Members',
              },
              {
                id: 3,
                buttonText: 'Assign Due Dates',
              },
              {
                id: 4,
                buttonText: 'Android App',
              },
            ].map(({ id, buttonText }) => (
              <button
                onClick={mobileAppTrigger(id)}
                className={mobileAppTabs === id ? 'active' : ''}
                type="button">
                {buttonText}
              </button>
            ))}
          </div>

          <div className="prod-tab-desc center">
            {[
              {
                id: 0,
                description: (
                  <>
                    Report issues directly from a mobile device without the need
                    for screenshots.
                  </>
                ),
                img: '/assets/img/report-bugs-mobile.webp',
              },
              {
                id: 1,
                description: (
                  <>
                    Track all your mobile app bugs in Kanban style, without any
                    additional integration steps needed.
                  </>
                ),
                img: '/assets/img/workflow-mobile.webp',
              },
              {
                id: 2,
                description: (
                  <>
                    Assign the tickets to your team members and track the
                    progress.
                  </>
                ),
                img: '/assets/img/assign-members-mobile.webp',
              },
              {
                id: 3,
                description: (
                  <>
                    Set deadlines for your ticket and get notified when you miss
                    the dates.
                  </>
                ),
                img: '/assets/img/assign-due-date-mobile.webp',
              },
              {
                id: 4,
                description: (
                  <>
                    Review your Android app with ruttl. The iOS is coming soon.
                  </>
                ),
                img: '/assets/img/android-app.webp',
              },
            ].map(({ id, description, img }) => (
              <div
                key={id}
                className={`prod-tab-pane ${
                  mobileAppTabs === id ? 'active' : ''
                }`}>
                <p className="center">{description}</p>

                <div className="prod-img">
                  <img src={img} alt="" loading="lazy" />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          data-spy-title="Customer Feedback"
          data-spy-id="customer-feedback"
          className="single-product-section">
          <div className="center">
            <div className="top-product-icon">
              <img
                src="/assets/img/customer-feedback-icon.svg"
                alt="Customer Feedback Logo"
              />
            </div>

            <h2 className="mx-auto" style={{ maxWidth: 570 }}>
              Customer Feedback
            </h2>

            <Link href="/customer-feedback-tool/" className="view-prod-button">
              View Product
              <svg width="6" height="12" viewBox="0 0 6 12" fill="none">
                <path
                  d="m1.031 10.063 3.938-4.22L1.03 1.626"
                  stroke="#6552FF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          </div>

          <div className="prod-tab-buttons">
            {[
              {
                id: 0,
                buttonText: ' Report Issues',
              },
              {
                id: 1,
                buttonText: ' Live chat',
              },
              {
                id: 2,
                buttonText: 'Feature Request',
              },
              {
                id: 3,
                buttonText: 'Support Dashboard',
              },
              {
                id: 4,
                buttonText: 'Customisation',
              },
            ].map(({ id, buttonText }) => (
              <button
                onClick={customerFeedbackTrigger(id)}
                className={customerFeedbackTabs === id ? 'active' : ''}
                type="button">
                {buttonText}
              </button>
            ))}
          </div>

          <div className="prod-tab-desc center">
            {[
              {
                id: 0,
                description: (
                  <>
                    Collect annotated issues from users in real-time with all
                    the device details, like browser, browser version, OS,
                    viewport resolution, etc
                  </>
                ),
                img: '/assets/img/report-issues.webp',
              },
              {
                id: 1,
                description: (
                  <>
                    Add a site widget, chat live with your (customer support)
                    agents and gather real-time insights.
                  </>
                ),
                img: '/assets/img/live-chat.webp',
              },
              {
                id: 2,
                description: (
                  <>
                    Collect and manage feature requests. Get your next best
                    feature from your users.
                  </>
                ),
                img: '/assets/img/feature-request.webp',
              },
              {
                id: 3,
                description: (
                  <>
                    Manage all your conversation with users in a single place.
                    Convert a conversation to a ticket if needed.
                  </>
                ),
                img: '/assets/img/support-dashboard.webp',
              },
              {
                id: 4,
                description: (
                  <>
                    Customise the look and feel of your chat widget. Replace the
                    icon or set its custom size, use your brand colours, etc.
                  </>
                ),
                img: '/assets/img/customization.webp',
              },
            ].map(({ id, description, img }) => (
              <div
                key={id}
                className={`prod-tab-pane ${
                  customerFeedbackTabs === id ? 'active' : ''
                }`}>
                <p className="center">{description}</p>

                <div className="prod-img">
                  <img src={img} alt="" loading="lazy" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <TestimonialsSlider />
      <div className="section-gap">
        <div className="container">
          <div className="footer-cta-block">
            <h2>ruttl integrates with your favorite software</h2>

            <Link href="/integrations/" className="button">
              View all Integrations
            </Link>
          </div>
        </div>
      </div>
      <section className="section-gap">
        <div className="container">
          <div className="flex-row justify-content-center">
            {[
              {
                id: 0,
                icon: '/assets/img/website-feedback-icon.svg',
                heading: 'Website Feedback',
                text: 'Comment on live websites, report bugs, and make real-time CSS edits for precise feedback',
                url: '/website-feedback-tool/',
              },

              {
                id: 1,
                icon: '/assets/img/mobile-app-feedback-icon.svg',
                heading: 'Mobile App Feedback',
                text: 'Mark issues directly on mobile application, organise feedback using our internal workflow',
                url: '/mobile-app-feedback/',
              },
              {
                id: 2,
                icon: '/assets/img/customer-feedback-icon.svg',
                heading: 'Customer Feedback',
                text: 'Chat with your website users in real time & resolve their issues with your team',
                url: '/customer-feedback-tool/',
              },
            ].map(({ id, icon, heading, text, url }) => (
              <div key={id} className="col col-3 col-md-6">
                <Link to={url} className="icon-text-box">
                  <div className="box-icon">
                    <img src={icon} alt="" />
                  </div>
                  <div className="box-desc">
                    <h3>{heading}</h3>
                    <p>{text}</p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Homepage;
